/* eslint-disable @typescript-eslint/no-explicit-any */
import api from "../api";
import { AxiosError } from "axios";
import { returnResponse } from "../utils";
import { LogOperation } from "../../utils/entities/logOperation";
import { formatYmd } from "../../utils/format";

export const SystemConfigurationService = {
  getAllByCustomer: async (customerId: number) => {
    try {
      const { data } = await api.get<any>(
        `/SystemConfiguration/GetAllByCustomerId?customerId=${customerId}`
      );

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        const errors = response?.data?.errors;
        const { severity, status, message, data } = returnResponse({
          statusCode: response?.status,
          data: response?.data,
          message: Array.isArray(errors) && errors.length > 0 ? errors[0]?.message : "Error",
        });

        return {
          severity,
          status,
          message: message || "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  put: async (_: string | null, systemConfiguration: any) => {
    try {
      const body = {
        ...systemConfiguration,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };
      const { data } = await api.put<any>(`/SystemConfiguration/Update`, body);

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  patch: async (value: string, id: string) => {
    try {
      const replaceBody = [
        {
          op: "replace",
          value: value,
          path: "value",
        },
        {
          op: "2",
          value: LogOperation.getDevice(),
          path: "device",
        },
        {
          op: "2",
          value: LogOperation.getMachineIP(),
          path: "ip",
        },
        {
          op: "2",
          value: LogOperation.getPoliceUnitId(),
          path: "logPoliceUnitId",
        },
        {
          op: "2",
          value: LogOperation.getUserId(),
          path: "logUserId",
        },
      ];

      const { data } = await api.patch<any>(
        `/SystemConfiguration/Update?id=${id}`,
        replaceBody
      );

      return {
        success: true,
        body: data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },

  getWatermark: async (id: number) => {
    try {
      const { data } = await api.get(`/SystemWatermark/Get?id=${id}`);

      return {
        success: true,
        data: data.data,
      };
    } catch (error) {
      return {
        success: false,
        data: {},
      };
    }
  },

  updateWatermark: async (
    id: number,
    label: string,
    layout: number,
    filepath: string
  ) => {
    try {
      const body = {
        id: id,
        customerId: LogOperation.getCustomerId(),
        filepath: filepath,
        layout: layout,
        label: label,
        active: true,
        createIn: formatYmd(new Date()),
        updateIn: formatYmd(new Date()),
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };

      const { data } = await api.put(`/SystemWatermark/Update`, body);

      return {
        success: true,
        data: data.data,
      };
    } catch (error) {
      return {
        success: false,
        data: {},
      };
    }
  },

  uploadFile: async (file: File,containerName:string, fileName: string) => {
    try {
      const fileItem = new FormData();
      fileItem.append("content", file, fileName);
      const { data } = await api.post(
        `/HearingFile/UploadFile?container=${containerName}`,
        fileItem
      );

      return {
        success: true,
        data,
      };
    } catch (error) {
      return {
        success: false,
        data: {},
      };
    }
  },

  uploadFileLarge: async (file: File, payload: any) => {
    try {
      const { data } = await api.post(
        `/hearingfile/UploadLocalFile`,
        file,
        payload
      );
      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },

  getVideoAuthenticityValidation: async (name: any, processNumber: any) => {
    try {
      const { data } = await api.get(`/HearingFile/VideoAuthenticityValidation?fileName=${name}&&processNumber=${processNumber}`);
      await LogOperation.postLogOperation("HearingFile", 9, 9);
      return data;
    } catch (error: any) {
      return error.response.data;
    }
  },

};
