/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";

import { Container, FullViewComponente } from "./styles";
import { useQuery } from "../../../utils/get";
import { hearingTranscription } from "@kentarepo/kcf-services";
import api from "../../../services/api";
import { v4 } from "uuid";
import { transcriptionServices } from "../../../services/transcription";
import { useToast } from "../../../hooks/toast";
import { usePermissions } from "../../../hooks/permission";
import { FORMS_HEARING } from "../../../utils/entities/permission";
import { IMarkingType } from "../../../features/fullview/RightTemplate/Mark/interface";
import { AxiosError } from "axios";
import { MarkingChildrenModal } from "./MarkingChildren";
import { markingService } from "../../../services/marking";
import { LogOperation } from "../../../utils/entities/logOperation";
import { IHearingIndexUpdate } from "../../../services/marking/interface";
import { LoadSpinner } from "../../../features/LoadSpinner";
import { customerTemplateService } from "../../../services/costumerTemplate";
import { formatYmd } from "../../../utils/format";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { HearingMinuteServices } from "../../../services/minute";

import {
  DrawerDefault,
  ModalDefault,
  SearchEmpty,
} from "@kentarepo/kcf-assets";

let countdownTimeout: NodeJS.Timeout;
let countdownTimeoutIndex: NodeJS.Timeout;

export const FullViewPage: React.FC = () => {
  const query = useQuery();

  const queryString = window.location.search;

  const { addToast } = useToast();
  const [transcriptionList, setTranscriptionList] = useState<Array<any>>([]);
  const [requestStatus, setRequestStatus] = useState<boolean>(false);

  const summaryRef: MutableRefObject<any> = useRef(null);
  const AtaRef: MutableRefObject<any> = useRef(null);
  const transcriptionRef: MutableRefObject<any> = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [markingList, setMarkingList] = useState<Array<any>>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isBegining, setIsBegining] = useState(true);
  const [attendantId, setAttendantId] = useState<number | undefined>();
  const [markingError, setMarkingError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemMarking, setItemMarking] = useState<any | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [modelList, setModelList] = useState<any>([]);
  const [transcriptionSelect, setTranscriptionSelect] = useState<string>("");
  const [page, setPage] = useState(0)
  const [selectTemplate, setSelectTemplate] = useState<any>();
  const [ata, setAta] = useState<any>([]);
  const [selectAtaTemplate, setSelectAtaTemplate] = useState<any>();
  const [loadingAba, setLoadingAba] = useState(false);
  const user = getValueFromlocalStorage("@Kenta:user");
  const userId: any = user ? JSON.parse(user)?.id : null;
  const [showModelInput, setShowModelInput] = useState<boolean>(false);
  const [marking, setMarking] = useState<IMarkingType>({
    toggleMarkingTime: () => ({}),
    toggleRate: (_rate: number) => ({}),
    toggleVideoPlay: () => ({}),
    hearingRecord: {},
    playerState: {
      playing: false,
      progress: 0,
      currentTime: "00:00",
      duration: "00:00",
      offset1: "00:00",
      offset2: "00:00",
      secondsCurrentTime: 0,
      secondsDuration: 0,
      volume: 1,
      playbackRate: 1,
      marking: null,
    },
  });

  const [changedTextAta, setChangedTextAta] = useState<boolean>(true);







  const [requestStatusIndex, setRequestStatusIndex] = useState<boolean>(false);

  const { read } = usePermissions(FORMS_HEARING.HEARING_FILE);
  const hearingFileId = query.get("hearingFileId");
  const hearingId = query.get("hearingId");

  function timeToSeconds(timeString: string) {
    const timeArray = timeString.split(":");
    const minutes = parseInt(timeArray[0]);
    const seconds = parseInt(timeArray[1]);
    const totalSeconds = minutes * 60 + seconds;
    return totalSeconds;
  }

  function secondsToTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = ("0" + minutes).slice(-2);
    const formattedSeconds = ("0" + remainingSeconds).slice(-2);
    const timeString = formattedMinutes + ":" + formattedSeconds;
    return timeString;
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    closeModal();
  };

  const onDeleteMarking = (item: any) => {
    setItemMarking(item);
    setShowDeleteModal(true);
  };

  const deleteMarking = async () => {
    try {
      closeDeleteModal();
      setLoading(true);
      await markingService.delete(itemMarking?.id);
      await LogOperation.postLogOperation("HearingIndex", 3, itemMarking?.id);
      await getIndexList();
      setLoading(false);
      addToast({
        title: "Deletar marcação",
        description: "Sua marcação foi deletada com sucesso",
        type: "success",
      });
    } catch (error) {
      setLoading(false);
      setCurrentIndex(-1);
      addToast({
        title: "Deletar marcação",
        description: "Ocorreu um error ao deletar sua marcação",
        type: "warning",
      });
    }
  };

  const getIndexList = async () => {
    try {
      const { data } = await api.get(
        `/HearingIndex/GetAllByHearingFileId?hearingFileId=${hearingFileId}`
      );
      if (data?.data?.length > 0) {
        const responseSorted = data.data.sort((a: any, b: any) => {
          return a.offSet > b.offSet ? 0 : -1;
        });
        setMarkingList(responseSorted);
        if (Boolean(data.data.find((x: any) => x.status >= 0 && x.status < 3))) {
          return setRequestStatusIndex(true);
        }
        setRequestStatusIndex(false);
      } else {
        setMarkingList(data.data);
      }
    } catch (error) {
      setLoading(false);
      addToast({
        title: "Problema ao carregar audiência",
        description: "Ocorreu um problema ao carregar os dados da audiência. Por favor, tente novamente.",
        type: "warning",
      });
    }
  };

  const onEditMarking = (item: any) => {
    setItemMarking(item);
    setMarking({
      ...marking,
      playerState: {
        ...marking.playerState,
        offset1: secondsToTime(item.offSet1),
        offset2: secondsToTime(item.offSet2),
      },
    });
    setAttendantId(item.attendantId);
    setIsOpen(true);
    setIsEdit(true);
    setSubject(item.subject);
  };

  async function processarCortes() {
    if (markingList?.length > 0) {
      try {
        await api.post(
          "/HearingIndex/SplitUpVideoByHearingFileId",
          {
            device: LogOperation.getDevice(),
            ip: LogOperation.getMachineIP(),
            logPoliceUnitId: LogOperation.getPoliceUnitId(),
            logUserId: LogOperation.getUserId(),
          },
          {
            params: {
              hearingFileId: Number(hearingFileId),
            },
          }
        );
        addToast({
          type: "success",
          title: "Sucesso",
          description: "Processando cortes",
        });
        await LogOperation.postLogOperation("HearingIndex", 8, 8);

        await getIndexList();
      } catch (error) {
        if (error instanceof AxiosError) {
          addToast({
            type: "error",
            title: "Erro",
            description: error.response?.data?.message,
          });
        } else {
          addToast({
            title: "Erro ao processar cortes",
            description: "Houve um erro ao tentar processar os cortes",
            type: "warning",
          });
        }
      }
    }
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await getAllByHearingFileId();
        await onUpdateStatusIfNotFinished();
        await getIndexList();
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onUpdateStatusIfNotFinished = async () => {
    while (requestStatus) {
      const timeout = setTimeout(() => {
        (async () => {
          await getAllByHearingFileId();
        })();
      }, 5000);

      clearTimeout(timeout);
    }
  };

  function resetCountdown() {
    clearTimeout(countdownTimeout);
    setRequestStatus(false);
  }

  function resetCountdownIndex() {
    clearTimeout(countdownTimeoutIndex);
    setRequestStatusIndex(false);
  }

  useEffect(() => {
    if (requestStatus) {
      countdownTimeout = setTimeout(() => {
        (async () => {
          await getAllByHearingFileId();
        })();
      }, 5000);
    } else {
      resetCountdown();
    }
  }, [transcriptionList, requestStatus]);

  useEffect(() => {
    if (requestStatusIndex) {
      countdownTimeoutIndex = setTimeout(() => {
        (async () => {
          await getIndexList();
        })();
      }, 10000);
    } else {
      resetCountdownIndex();
    }
  }, [markingList, requestStatusIndex]);

  async function createTranscription() {
    const hearingFileId = query.get("hearingFileId");
    const hearingTranscriptionServices = hearingTranscription(api);

    if (transcriptionSelect === 'automatico') {
      if (!selectTemplate) {
        return addToast({
          type: "warning",
          title: "Aviso",
          description:
            "Para criar uma transcrição automática é necessário selecionar um modelo",
        });
      }
      const { success } = await hearingTranscriptionServices.addTranscription({
        active: true,
        autoTranscription: true,
        device: LogOperation.getDevice(),
        sendEmail: true,
        guid: v4(),
        title: `Transcrição da audiencia nº: ${hearingFileId}`,
        hearingFileId: Number(hearingFileId),
        customerTemplateId: selectTemplate.id,
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
        userId: userId,
      });
      setShowModelInput(false)
      if (success) getAllByHearingFileId();

      addToast({
        type: "success",
        title: "Transcrição automatica iniciada.",
        description: "Sua transcrição foi iniciada com sucesso.",
      });
    }

    if (transcriptionSelect === 'manual') {
      const data = {
        id: 0,
        guid: v4(),
        hearingFileId: Number(hearingFileId),
        userId: userId,
        customerTemplateId: modelList[0]?.id,
        title: `Transcrição da audiencia nº: ${hearingFileId}`,
        autoTranscription: false,
        createIn: formatYmd(new Date()),
        updateIn: formatYmd(new Date()),
        status: 6,
        sendEmail: false,
        active: true,
        device: LogOperation.getDevice(),
        ip: LogOperation.getMachineIP(),
        logPoliceUnitId: LogOperation.getPoliceUnitId(),
        logUserId: LogOperation.getUserId(),
      };
      const { success } = await hearingTranscriptionServices.addTranscription(data)
      setSelectTemplate(modelList[0])
      setShowModelInput(true)
      if (success) getAllByHearingFileId();
      addToast({
        type: "success",
        title: "Transcrição manual criada.",
        description: "Sua transcrição foi criada com sucesso.",
      });
    }
  }

  async function updateTranscription(by: "summary" | "transcription") {
    if (
      transcriptionList &&
      transcriptionList[0] &&
      transcriptionList[0].autoTranscription
    ) {
      await transcriptionServices.patchDisableAutoTranscription(
        transcriptionList[0]?.id
      );
    }

    if (by === "transcription") {
      if (transcriptionRef.current && transcriptionRef.current.getContent()) {
        const { success } = await transcriptionServices.patch({
          textPlain: transcriptionRef.current.getContent(),
          id: transcriptionList[0]?.id,
        });

        await getAllByHearingFileId();
        await transcriptionServices.patchTemplate({ id: transcriptionList[0]?.id, customerTemplateId: selectTemplate?.id })

        if (success) {
          addToast({
            type: "success",
            title: "Transcrição atualizada.",
            description: "Sua transcrição foi atualizada com sucesso.",
          });
        }

        if (!success) {
          addToast({
            type: "warning",
            title: "Transcrição não atualizada",
            description: "A transcrição não pode ser atualizada",
          });
        }
      }
    }

    if (by === "summary") {
      if (summaryRef.current && summaryRef.current.getContent()) {
        const { success } = await transcriptionServices.patch({
          textPlainSummary: summaryRef.current.getContent(),
          id: transcriptionList[0].id,
        });

        await getAllByHearingFileId();

        if (success) {
          addToast({
            type: "success",
            title: "Resumo atualizada.",
            description: "Seu resumo foi atualizada com sucesso.",
          });
        }

        if (!success) {
          addToast({
            type: "warning",
            title: "Resumo não atualizada",
            description: "O resumo não pode ser atualizada",
          });
        }
      }
    }
  }

  async function getAllByHearingFileId() {
    const hearingFileId = query.get("hearingFileId");
    const hearingTranscriptionServices = hearingTranscription(api);

    const { data, success } =
      await hearingTranscriptionServices.getAllByHearingFileId(
        Number(hearingFileId)
      );

    if (success) {
      setTranscriptionList(data);
      if (data && data[0] && data[0]?.status !== 6) {
        return setRequestStatus(true);
      }

      setRequestStatus(false);
    }
  }

  const closeModal = () => {
    setIsOpen(false);
    setIsEdit(false);
    setItemMarking(null);
    setCurrentIndex(-1);
    setMarkingError(false);
  };

  const finishCorrection = () => {
    setSubject("");
    setMarking({
      toggleMarkingTime: () => ({}),
      toggleRate: (_rate: number) => ({}),
      toggleVideoPlay: () => ({}),
      hearingRecord: {},
      playerState: {
        playing: false,
        progress: 0,
        currentTime: marking.playerState.offset2,
        duration: "00:00",
        offset1: marking.playerState.offset2,
        offset2: "00:00",
        secondsCurrentTime: 0,
        secondsDuration: 0,
        volume: 1,
        playbackRate: 1,
        marking: null,
      },
    });
  };

  const createNewMarking = async () => {
    setIsBegining(false);

    try {
      if (!attendantId) {
        addToast({
          title: "Criação de marcação",
          description:
            "Para criar uma marcação é necessário selecionar um participante.",
          type: "warning",
        });

        return setMarkingError(true);
      }

      if (
        !isBegining &&
        marking.playerState.offset2 < marking.playerState.offset1
      ) {
        addToast({
          title: "Marcação inválida",
          description:
            "Não é possível criar uma marcação com o final menor que o inicio",
          type: "warning",
        });

        closeModal();
        return;
      }

      if (
        !isBegining &&
        marking.playerState.offset1 === "00:00" && marking.playerState.offset2 === "00:00"
      ) {
        addToast({
          title: "Marcação inválida",
          description:
            "Não é possível criar uma marcação se o tempo inicial e final forem zero",
          type: "warning",
        });

        closeModal();
        return;
      }

      if (!isBegining && (!subject || subject === "")) {
        addToast({
          title: "Marcação inválida",
          description: "Não é possível criar uma marcação sem descrição",
          type: "warning",
        });
        return;
      }

      if (!isBegining) {
        const body = {
          hearingId: Number(hearingId),
          hearingFileId: Number(hearingFileId),
          attendantId: attendantId,
          offSet1: Number(timeToSeconds(marking.playerState.offset1)),
          offSet2: Number(timeToSeconds(marking.playerState.offset2)),
          recordDate: new Date(),
          duration: marking.playerState.secondsDuration,
          subject: subject,
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        };

        await api.post("/HearingIndex/Add", body);

        await getIndexList();

        closeModal();
        finishCorrection();
        setIsBegining(true);
        addToast({
          title: "Marcação finalizada",
          description: "Sua marcação foi criada com sucesso.",
          type: "success",
        });
      } else {
        addToast({
          title: "Marcação iniciada",
          description: "Marcação iniciada: Passo 1 de 2",
          type: "success",
        });
      }
      closeModal();
    } catch (error) {
      console.log(error);

      if (error instanceof AxiosError) {
        addToast({
          type: "error",
          title: "Erro",
          description: error.response?.data.message,
        });
      } else {
        addToast({
          title: "Erro na marcação",
          description:
            "Houve um error ao criar a marcação, tente novamente mais tarde ou entre em contato com o suporte.",
          type: "warning",
        });
      }
    }
  };

  function getRawQueryValue(param: any) {
    const rawValue = queryString.split(`${param}=`)[1];
    return rawValue ? rawValue.split('&').join('&') : null;
  }

  const handleUpdateMarking = async () => {
    try {
      if (itemMarking && attendantId) {
        const body: IHearingIndexUpdate = {
          id: itemMarking?.id,
          hearingId: itemMarking.hearingId,
          attendantId,
          hearingFileId: itemMarking.hearingFileId,
          offset1: parseInt(marking.playerState.offset1),
          offset2: parseInt(marking.playerState.offset2),
          duration: marking.playerState.secondsDuration,
          subject: subject,
          status: itemMarking.status,
          device: LogOperation.getDevice(),
          ip: LogOperation.getMachineIP(),
          logPoliceUnitId: LogOperation.getPoliceUnitId(),
          logUserId: LogOperation.getUserId(),
        };

        await markingService.update(body);
        await getIndexList();
        closeModal();

        addToast({
          title: "Atualização de marcação",
          description: "A atualização da marcação foi feita com sucesso",
          type: "success",
        });
      }
    } catch (error) {
      addToast({
        title: "Atualização de marcação",
        description: "Ocorreu um error ao atualizar sua marcação",
        type: "warning",
      });
    }
  };

  function summaryFunction() {
    if (!(transcriptionList && transcriptionList[0])) {
      addToast({
        type: "warning",
        title: "Inicie uma transcrição",
        description: "Para salvar inicie uma transcrição automática",
      });
    }
  }

  const summary = {
    buttonFunction:
      transcriptionList &&
        transcriptionList[0] &&
        transcriptionList[0]?.status === 6
        ? () => updateTranscription("summary")
        : () => summaryFunction(),
    buttonTitle:
      transcriptionList && transcriptionList[0]
        ? transcriptionList[0]?.status !== 6
          ? "Iniciar"
          : "Salvar"
        : "Iniciar",
    disabledButton:
      transcriptionList && transcriptionList[0]
        ? transcriptionList[0]?.status !== 6
        : false,
    status:
      transcriptionList && transcriptionList[0]
        ? transcriptionList[0]?.status
        : null,
  };

  const transcription = {
    buttonFunction:
      transcriptionList &&
        transcriptionList[0] &&
        transcriptionList[0]?.status === 6
        ? () => updateTranscription("transcription")
        : createTranscription,
    buttonTitle:
      transcriptionList && transcriptionList[0]
        ? transcriptionList[0]?.status !== 6
          ? "Iniciar"
          : "Salvar"
        : "Iniciar",
    disabledButton:
      transcriptionList && transcriptionList[0]
        ? transcriptionList[0]?.status !== 6
        : false,
    status:
      transcriptionList && transcriptionList[0]
        ? transcriptionList[0]?.status
        : null,
  };

  const loadingModel = async (type: number) => {
    // setLoadingAba(true)
    try {
      setModelList([]);
      const { body, success } =
        await customerTemplateService.getAllByCustomerId({
          active: true,
          customerId: LogOperation.getCustomerId(),
          type: type,
        });

      if (success) {
        const list = body.map((item: any) => ({ ...item, name: item.title }));

        setModelList(list);
      }
      setLoadingAba(false)
    } catch (error) {
      return error
    }
    setLoadingAba(false)

  };

  useEffect(() => {
    if (page === 1 || page === 3) loadingModel(page === 1 ? 2 : 1);
  }, [page]);

  // ATA
  const getAtaList = async () => {
    const { success, body } = await HearingMinuteServices.getAllByHearingId({
      hearingId: hearingId ? hearingId : "",
    });

    if (success) setAta(body);
    if (!success) {
      addToast({
        type: "warning",
        title: "Ata da oitiva",
        description: body,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (page === 3) {
        await getAtaList();
      }
    })();
  }, [page]);

  const saveAta = async () => {
    if (AtaRef.current && AtaRef.current.getContent()) {
      if (ata && ata[0] && ata[0]?.id) {
        const { success } = await HearingMinuteServices.update({
          id: ata[0]?.id,
          hearingId: hearingId ? hearingId : "",
          content: AtaRef.current.getContent(),
          customerTemplateId: selectAtaTemplate?.id
        });

        if (
          ata &&
          ata.length &&
          ata[0]?.id &&
          selectAtaTemplate &&
          selectAtaTemplate?.id
        ) {
          HearingMinuteServices.patchTemplate({
            id: ata[0]?.id,
            customerTemplateId: selectAtaTemplate?.id,
          });
        }

        if (success) {
          addToast({
            type: "success",
            title: "Ata da oitiva",
            description: "Ata da oitiva foi atualizada com sucesso",
          });

          await getAtaList();
        }

        if (!success) {
          addToast({
            type: "warning",
            title: "Ata da oitiva",
            description: "Ata da oitiva não foi atualizada.",
          });
        }
      } else {
        const { success } = await HearingMinuteServices.add({
          hearingId: hearingId ? hearingId : "",
          content: AtaRef.current.getContent(),
          customerTemplateId:
            selectAtaTemplate && selectAtaTemplate?.id ? selectAtaTemplate?.id : 0,
        });

        await getAtaList();
        if (success) {
          addToast({
            type: "success",
            title: "Ata da oitiva",
            description: "Ata da oitiva foi salva com sucesso",
          });
        }

        if (!success) {
          addToast({
            type: "warning",
            title: "Ata da oitiva",
            description: "Ata da oitiva não foi salva com sucesso",
          });
        }
      }
    }
  };

  return (
    <Fragment>
      <Container>
        {read ? (
          <FullViewComponente
            loadingAba={loadingAba}
            setPage={setPage}
            summary={{
              ...summary,
              ataRef: summaryRef,
              transcription: transcriptionList,
            }}
            mark={{
              currentIndex,
              setCurrentIndex,
              data: markingList,
              marking: marking,
              setHearingItemDto: () => { },
              onDeleteMarking,
              onEditMarking,
              isBeginin: isBegining,
              processCuts: processarCortes,
              setOpenModal: setIsOpen,
              loadingAba
            }}
            transcription={{
              ...transcription,
              ataRef: transcriptionRef,
              transcription: transcriptionList,
              marking: marking,
              modelList: modelList,
              transcriptionSelect: transcriptionSelect,
              setTranscriptionSelect: setTranscriptionSelect,
              selectTemplate: selectTemplate,
              setSelectTemplate: setSelectTemplate,
              setShowModelInput: setShowModelInput,
              showModelInput: showModelInput,
              getAllByHearingFileId: getAllByHearingFileId,
              hearingId: hearingId,
              loadingAba,
              hearingFileId: Number(hearingFileId)
            }}
            ata={{
              ataRef: AtaRef,
              buttonFunction: saveAta,
              buttonTitle: "Salvar",
              disabledButton: false,
              status: 0,
              ata: ata,
              modelList,
              setSelectTemplate: setSelectAtaTemplate,
              selectTemplate: selectAtaTemplate,
              loadingAba,
              changedTextAta: changedTextAta,
              setChangedTextAta: setChangedTextAta
            }}
            title={`Audiência: ${query.get("audienceNumber") || ""}`}
            videoComponente={{
              videoUrl:
                `${getRawQueryValue("audienceVideo")}` || "",
              isBegining,
              modalIsOpen: false,
              setMarking,
              marking,
            }}
            subTitle=""
          />
        ) : (
          <SearchEmpty
            typeIcon="avatar"
            textMessage="Sem permissão para seu perfil"
            marginTop="200px"
          />
        )}
      </Container>
      <DrawerDefault
        openDrawer={isOpen}
        setOpenDrawer={() => {
          closeModal();
          setSubject("");
          setAttendantId(undefined);
        }}
        title={isEdit ? "Editar marcação" : "Criar marcação"}
        paddingleft={28}
        paddingright={32}
        onClose={() => setIsOpen(false)}
        onApply={isEdit ? handleUpdateMarking : createNewMarking}
        children={
          <MarkingChildrenModal
            isEdit={isEdit}
            setAttendantId={setAttendantId}
            attendantId={attendantId}
            subject={subject}
            setSubject={setSubject}
            toEditItem={itemMarking}
            marking={marking}
            error={markingError}
            isBegining={isBegining}
          />
        }
      />

      <ModalDefault
        openModal={showDeleteModal}
        setOpenModal={setShowDeleteModal}
        title={"Excluir marcação"}
        bodyMessageTitle={"Deseja excluir a marcação?"}
        bodyMessageSubTitle={"Esta ação não poderá ser desfeita"}
        textConfirm={"EXCLUIR"}
        onClickConfirm={deleteMarking}
        textCancel={"CANCELAR"}
        bodyMessageMarginBetweenTitleAndSubTitle="30px"
        bodyMessageMarginTop="30px"
        onClickCancel={() => {
          setShowDeleteModal(false);
        }}
      />

      <LoadSpinner loading={loading} />
    </Fragment>
  );
};
